import React from 'react'
import { Link, graphql } from "gatsby"
import "../styles/templates/single-team-page.scss"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"

const pitcherPositionValues = ["P", "SP", "RP", "CP", "LHP", "RHP"]

export default ({ location, data }) => {

  const { team } = data

  const positionPlayers = team.roster.filter((player) => {
    if (!pitcherPositionValues.includes(player.position)) {
      return player
    } else { return null }
  })

  const pitchers = team.roster.filter((player) => {
    if (pitcherPositionValues.includes(player.position)) {
      return player
    } else { return null }
  })

  return (
    <Layout location={location} cssPageName="single-team">
      <SEO title={data.team.title} />
      <div className="header-flex">
        <img className="team-logo" src={team.logo} alt={team.title} />
        <h1 className="team-title">{team.title}</h1>
      </div>
      <div className="sub-header-flex">
        <div className="team-record">W-L: {team.record.wins}-{team.record.losses}</div>
        <div className="social-links">
          {team.socialMediaHandles !== null && team.socialMediaHandles.facebook !== "" && <a href={`https://facebook.com/${team.socialMediaHandles.facebook}`} target="_blank" rel="noopener noreferrer"><img className="social-link" src="/facebook.png" alt="Visit team's Facebook page." /></a>}
          {team.socialMediaHandles !== null && team.socialMediaHandles.instagram !== "" && <a href={`https://instagram.com/${team.socialMediaHandles.instagram}`} target="_blank" rel="noopener noreferrer"><img className="social-link" src="/instagram.png" alt="Visit team's Instagram page." /></a>}
          {team.socialMediaHandles !== null && team.socialMediaHandles.twitter !== "" && <a href={`https://twitter.com/${team.socialMediaHandles.twitter}`} target="_blank" rel="noopener noreferrer"><img className="social-link" src="/twitter.png" alt="Visit team's Twitter page." /></a>}
        </div>
        <div className="button-container">
          <Link className="team-stats-link" to="/stats-and-standings#stats">Team Stats</Link>
        </div>
      </div>
      <section className="personnel-container">
        <div className="coaching-staff">
          <h2 className="coaching-staff-title">Coaching Staff</h2>
          <ul className="coach-listing">
            {team.coaches.map((coach) => (
              <li key={coach.name} className="coach-list-item">
                <div className="image-container">
                  <img className="coach-image" src={coach.image} alt={coach.name} />
                </div>
                <div className="content-container">
                  <h3 className="coach-title">{coach.coachesTitle}</h3>
                  <h4 className="coach-name">{coach.name}</h4>
                  <p className="coach-description">{coach.description}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="player-roster">
          <h2 className="player-roster-title">Player Roster</h2>
          <div className="position-players-container">
            <h3 className="position-players-title">Position Players</h3>
            {positionPlayers.length > 0 ? positionPlayers.map((player => (
              <div key={player.jerseyNumber} className="player"><span className="jersey-number">#{player.jerseyNumber}</span> <span className="name">{player.name}</span> <span className="position">{player.position}</span></div>
            ))) : <p>No position players yet!</p>}
          </div>
          <div className="pitchers-container">
            <h3 className="pitchers-title">Pitchers</h3>
            {pitchers.length > 0 ? pitchers.map((player => (
              <div key={player.jerseyNumber} className="player"><span className="jersey-number">#{player.jerseyNumber}</span> <span className="name">{player.name}</span> <span className="position">{player.position}</span></div>
            ))) : <p>No pitchers yet!</p>}
          </div>
        </div>
      </section>

      <section className="stadium">

      </section>

      <section className="owner">
        <h2 className="owner-title">Team Owner</h2>
        <div className="owner-flex">
          <div className="image-container">
            <img className="owner-image" src={team.owner.image} alt={team.owner.name} />
          </div>
          <div className="text-container">
            <h3 className="owner-name">{team.owner.name}</h3>
            <p className="owner-description">{team.owner.description}</p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
query SingleTeamBySlug($slug: String!) {
            team: teamsJson(fields: {slug: {eq: $slug}}) {
            id
      initials
      coaches {
            coachesTitle
        description
        image
        name
      }
      division
      owner {
            description
        image
        name
      }
      logo
      record {
            losses
        wins
      }
      roster {
        batHandedness
        name
        jerseyNumber
        position
        throwHandedness
      }
      socialMediaHandles {
            facebook
        instagram
        twitter
      }
      title
    }
  }

`